







































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AuditFlowScope,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  FundNonCashDonationRecordDto,
  SelectListItem,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";


@Component({
  name: "FundNonCashDonationDetail",
  components: {
    ExportWord,
    AuditBlock,
  },
})
export default class FundNonCashDonationDetail extends Vue {

  detail: FundNonCashDonationRecordDto = {};
  styles = "";
  selector = "table-detail-world";
  filename = "实物捐赠详情";
  loading = true;

  contactType: DataDictionaryDto[] = [];
  items: any = [];
  donationTypes: DataDictionaryDto[] = [];
  donatorTypes: SelectListItem[] = [];

  created() {
    if (this.$route.params.id) {
      this.fetchDetail();
    }
    this.$store.dispatch("enumValues/fetch", "DonatorType").then((res) => {
      this.donatorTypes = res;
    });
    this.$store.dispatch("dataDictionary/fetch", "ContactType").then((res) => {
      this.contactType = res;
    });
    this.$store
      .dispatch("dataDictionary/fetch", "NonCashDonationType")
      .then((res) => {
        console.log(
          "DataDictionaryModule fetch result for:NonCashDonationType",
          res
        );
        this.donationTypes = res;
      });
  }

  // 获取详情
  async fetchDetail() {
    this.loading = true;
    api.fundNonCashDonation
      .get({ id: Number(this.$route.params.id) })
      .then((res) => {
        this.detail = { ...res };
        if (this.detail && this.detail.nonCashDonationItemJson) {
          this.items = JSON.parse(this.detail.nonCashDonationItemJson);
        }
        this.loading = false;
      });
  }

  get auditApi(){
    return {canAudit: api.fundNonCashDonation.canAudit,userAudit: api.fundNonCashDonation.audit} as AuditApi
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
